import React, { useState } from "react";
import { Button, Spacer, HStack, IconButton } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

const RATINGS = [1, 2, 3, 4, 5];

const RatingQuestion = ({ onNext }) => {
  const [selected, setSelected] = useState();

  const handleClick = (rating) => () => {
    setSelected(rating);
  };

  const isSelected = (rating) => () => {
    if (rating <= selected) return "#ee8a19";
  };

  const handleNext = (e) => {
    e.preventDefault();

    onNext({ label: String(selected) });
  };

  return (
    <>
      <HStack>
        {RATINGS.map((rating) => (
          <IconButton
            key={rating}
            onClick={handleClick(rating)}
            icon={<StarIcon color={isSelected(rating)} />}
          />
        ))}
      </HStack>
      <Spacer my={4} />
      <Button onClick={handleNext}>Next</Button>
    </>
  );
};

export { RatingQuestion };
