import React, { useContext, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, Spacer } from "@chakra-ui/react";

import { Questionnaire } from ".";

const GET_ME = gql`
  query me {
    me {
      id
      domain
      questionnaires {
        id
        title
        isEnabled
        finalScreenTitle
        finalScreenMessage
        questions {
          id
          title
          type
          options {
            id
            label
          }
        }
      }
    }
  }
`;

const AppContainer = () => {
  const { data, loading, error } = useQuery(GET_ME);

  if (loading || error) {
    return null;
  }

  const questionnaire = data.me.questionnaires[0];

  if (!questionnaire) {
    return null;
  }

  const {
    isEnabled,
    questions,
    finalScreenTitle,
    finalScreenMessage,
  } = questionnaire;

  if (!isEnabled || !questions.length) {
    return null;
  }

  return (
    <>
      <Spacer my={8} />
      <Box border="1px solid #d9d9d9" borderRadius="4px" padding={4}>
        <Questionnaire
          questions={questions}
          finalScreenTitle={finalScreenTitle}
          finalScreenMessage={finalScreenMessage}
        />
      </Box>
    </>
  );
};

export { AppContainer };
