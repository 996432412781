import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import { AppContainer } from "./components";
import { resize } from "./utils/resize";
import {
  ShopifyDataContext,
  useShopifyData,
  useNotifyParentFrame,
} from "./hooks";

const theme = extendTheme({
  fonts: {
    heading:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;',
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;',
  },
});

const App = () => {
  useNotifyParentFrame("ready");
  useEffect(() => {
    resize();
  }, []);
  const shopifyData = useShopifyData();

  if (!shopifyData) {
    return null;
  }

  const client = new ApolloClient({
    uri: `${process.env.HOST}/public-graphql?shop=${shopifyData.shop}`,
    cache: new InMemoryCache(),
  });

  return (
    <ChakraProvider>
      <ApolloProvider client={client}>
        <ShopifyDataContext.Provider value={shopifyData}>
          <AppContainer />
        </ShopifyDataContext.Provider>
      </ApolloProvider>
    </ChakraProvider>
  );
};

const mountNode = document.querySelector("#root");

ReactDOM.render(<App />, mountNode);
