import { createContext, useEffect, useState } from "react";

const ShopifyDataContext = createContext(null);

const useShopifyData = () => {
  const [shopifyData, setShopifyData] = useState(null);

  useEffect(() => {
    const handleMessage = (e) => {
      if (Array.isArray(e.data)) {
        const [eventName, data] = e.data;

        switch (eventName) {
          case "receiveShopifyData":
            setShopifyData(JSON.parse(data));
            break;
        }
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return shopifyData
};

export { useShopifyData, ShopifyDataContext };
