import React from "react";
import { Heading, Spacer } from "@chakra-ui/react";

import {
  CheckboxQuestion,
  RadioQuestion,
  RatingQuestion,
  FreetextQuestion,
} from ".";

const Question = ({ question, onNext }) => {
  return (
    <>
      <Heading fontSize="25px" fontWeight="normal" color="#333333">
        {question.title}
      </Heading>
      <Spacer my={4} />
      {question.type === "checkbox" && (
        <CheckboxQuestion question={question} onNext={onNext} />
      )}
      {question.type === "radio" && (
        <RadioQuestion question={question} onNext={onNext} />
      )}
      {question.type === "rating" && <RatingQuestion onNext={onNext} />}
      {question.type === "freetext" && <FreetextQuestion onNext={onNext} />}
    </>
  );
};

export { Question };
