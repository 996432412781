import React, { useState } from "react";
import { Radio, RadioGroup, Spacer, Button, VStack } from "@chakra-ui/react";

const RadioQuestion = ({ question, onNext }) => {
  const [selected, setSelected] = useState({});

  const handleSelect = (id) => {
    const option = question.options.find((option) => option.id === id);

    setSelected(option);
  };

  const handleNext = (e) => {
    e.preventDefault();

    onNext(selected);
  };

  return (
    <>
      <RadioGroup>
        <VStack align="start">
          {question.options.map((option) => (
            <Radio
              colorScheme="green"
              key={option.id}
              size="lg"
              value={option.id}
              selected={selected.id === option.id}
              onChange={() => handleSelect(option.id)}
            >
              {option.label}
            </Radio>
          ))}
        </VStack>
      </RadioGroup>
      <Spacer my={4} />
      <Button onClick={handleNext}>Next</Button>
    </>
  );
};

export { RadioQuestion };
