import React, { useContext, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Heading, Spacer, Text } from "@chakra-ui/react";

import { Question } from ".";
import { resize } from "../utils/resize";
import { ShopifyDataContext } from "../hooks";

const ADD_QUESTION_RESPONSE = gql`
  mutation addQuestionResponse($input: AddQuestionResponseInput!) {
    addQuestionResponse(input: $input) {
      success
    }
  }
`;

const TRACK_QUESTION_VIEW = gql`
  mutation trackQuestionView($questionId: String!) {
    trackQuestionView(questionId: $questionId)
  }
`;

const Questionnaire = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [addQuestionResponse] = useMutation(ADD_QUESTION_RESPONSE);
  const [trackQuestion] = useMutation(TRACK_QUESTION_VIEW);
  const shopifyData = useContext(ShopifyDataContext);
  const { questions, finalScreenTitle, finalScreenMessage } = props;
  const question = questions[currentQuestion];

  useEffect(() => {
    resize();
  }, [question, isComplete]);

  useEffect(() => {
    trackQuestion({ variables: { questionId: question.id } });
  }, [question]);

  const onNext = async (selected) => {
    if (selected) {
      let responses;
      if (question.type === "checkbox") {
        responses = selected.map((option) => ({
          response: option,
          extra: null,
        }));
      }
      if (question.type === "radio" || question.type === "rating") {
        responses = [
          {
            response: selected.label,
            extra: null,
          },
        ];
      }
      if (question.type === "freetext") {
        responses = [
          {
            response: selected,
            extra: null,
          },
        ];
      }
      await addQuestionResponse({
        variables: {
          input: {
            question: question.id,
            orderId: String(shopifyData.checkout?.order_id),
            customerId: String(shopifyData.checkout?.customer_id),
            responses,
          },
        },
      });
    }

    const nextIndex = currentQuestion + 1;
    if (nextIndex <= questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setIsComplete(true);
    }
  };

  if (isComplete) {
    return (
      <>
        <Heading fontWeight="normal" fontSize="25px">
          {finalScreenTitle}
        </Heading>
        <Spacer my={4} />
        <Text>{finalScreenMessage}</Text>
      </>
    );
  }
  return <Question question={question} onNext={onNext} />;
};

export { Questionnaire };
