import React, { useState } from "react";
import { Checkbox, Button, Spacer, VStack } from "@chakra-ui/react";

const CheckboxQuestion = ({ question, onNext }) => {
  const [selectedOptions, setSelectionOptions] = useState([]);

  const handleNext = (e) => {
    e.preventDefault();

    onNext(selectedOptions.map((option) => option.label));
  };

  const isChecked = (id) => {
    return selectedOptions.some((option) => option.id === id);
  };

  const handleSelect = (option) => {
    if (isChecked(option.id)) {
      setSelectionOptions(selectedOptions.filter((o) => o.id !== option.id));
    } else {
      setSelectionOptions([...selectedOptions, option]);
    }
  };

  return (
    <>
      <VStack align="start">
        {question.options.map((option) => (
          <Checkbox
            colorScheme={"green"}
            size="lg"
            key={option.id}
            isChecked={isChecked(option.id)}
            onChange={() => handleSelect(option)}
          >
            {option.label}
          </Checkbox>
        ))}
      </VStack>
      <Spacer my={4} />
      <Button onClick={(e) => handleNext(e)}>Next</Button>
    </>
  );
};

export { CheckboxQuestion };
