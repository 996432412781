import React, { useState } from "react";
import { Textarea, Button, Spacer } from "@chakra-ui/react";

const FreetextQuestion = ({ onNext }) => {
  const [text, setText] = useState("");

  const handleNext = (e) => {
    e.preventDefault();

    onNext(text);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <>
      <Textarea value={text} onChange={handleChange} />
      <Spacer my={4} />
      <Button onClick={handleNext}>Next</Button>
    </>
  );
};

export { FreetextQuestion };
